<template>
    <v-container fluid>
        <v-data-table :headers="headers" :items="items" :items-per-page="10" calculate-widths :loading="loading_status"
            loader-height="10" loading-text="Cargando ..." dense>
            <template #top>
                <v-toolbar flat>
                    <h3>Rotación por Marca</h3>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-row class="pa-2">
                    <v-col cols="12" md="3">
                        <v-text-field v-model="dateFrom" label="Desde" hide-details="auto" required dense outlined
                            type="date" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field v-model="dateTo" label="Hasta" hide-details="auto" required dense outlined
                            type="date" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn color="success" @click="get_report('marca')">Buscar</v-btn>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:[`item.categoria`]="{ item }">
                <span>{{ item.categoria + " / " + item.grupo }}</span>
            </template>
            <template v-slot:[`item.brand`]="{ item }">
                <span>{{ item.brand }}</span>
            </template>
            <template v-slot:[`item.inv_i`]="{ item }">
                <span>{{ parseFloat(item.inv_i).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.qty_ingresos`]="{ item }">
                <span>{{ parseFloat(item.qty_ingresos).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.qty_sales`]="{ item }">
                <span>{{ parseFloat(item.qty_sales).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.inv_f`]="{ item }">
                <span>{{ parseFloat(item.inv_f).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.turnover`]="{ item }">
                <span>{{ parseFloat(item.turnover).toLocaleString(6) }}</span>
            </template>
            <template v-slot:[`item.roi`]="{ item }">
                <span>{{ parseFloat(item.roi).toLocaleString(6) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon class="mr-2" @click="showBrand(item)">
                    mdi-magnify
                </v-icon>
            </template>
            <template slot="body.append">
                <tr>
                    <th>Total</th>
                    <th></th>
                    <th class="text-right ">{{ sumTable('items', 'inv_i') }}</th>
                    <th class="text-right ">{{ sumTable('items', 'qty_ingresos') }}</th>
                    <th class="text-right ">{{ sumTable('items', 'qty_sales') }}</th>
                    <th class="text-right ">{{ sumTable('items', 'inv_f') }}</th>

                </tr>
            </template>

        </v-data-table>
        <v-dialog v-model="dialog" transition="dialog-fade-transition" scrollable max-width="800px"
            :fullscreen="$vuetify.breakpoint.smAndDown">
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar>
                        Rotación por referencia
                        <v-spacer></v-spacer>
                        <v-icon @click="dialog = !dialog"> mdi-close </v-icon>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-data-table :headers="headers_brand" :items="items_brand" :items-per-page="10" calculate-widths
                        :search="search" :loading="loading_status" loader-height="10" loading-text="Cargando ..." dense>
                        <template #top>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
                        </template>
                        <template v-slot:[`item.categoria`]="{ item }">
                            <span>{{ item.categoria + " / " + item.grupo }}</span>
                        </template>
                        <template v-slot:[`item.brand`]="{ item }">
                            <span>{{ item.brand }}</span>
                        </template>
                        <template v-slot:[`item.inv_i`]="{ item }">
                            <span>{{ parseFloat(item.inv_i).toLocaleString(2) }}</span>
                        </template>
                        <template v-slot:[`item.qty_ingresos`]="{ item }">
                            <span>{{ parseFloat(item.qty_ingresos).toLocaleString(2) }}</span>
                        </template>
                        <template v-slot:[`item.qty_sales`]="{ item }">
                            <span>{{ parseFloat(item.qty_sales).toLocaleString(2) }}</span>
                        </template>
                        <template v-slot:[`item.inv_f`]="{ item }">
                            <span>{{ parseFloat(item.inv_f).toLocaleString(2) }}</span>
                        </template>
                        <template v-slot:[`item.turnover`]="{ item }">
                            <span>{{ parseFloat(item.turnover).toLocaleString(6) }}</span>
                        </template>
                        <template v-slot:[`item.roi`]="{ item }">
                            <span>{{ parseFloat(item.roi).toLocaleString(6) }}</span>
                        </template>
                        <template slot="body.append">
                            <tr>
                                <th>Total</th>
                                <th></th>
                                <th></th>
                                <th class="text-right ">{{ sumTable('items_brand', 'inv_i') }}</th>
                                <th class="text-right ">{{ sumTable('items_brand', 'qty_ingresos') }}</th>
                                <th class="text-right ">{{ sumTable('items_brand', 'qty_sales') }}</th>
                                <th class="text-right ">{{ sumTable('items_brand', 'inv_f') }}</th>

                            </tr>
                        </template>

                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { webserver, getToday, getdays_ago } from "../services/webserver.js";

export default {
    components: {},
    data() {
        return {
            errors: [],
            valid: false,
            loading_status: false,
            f_required: [(v) => !!v || "Requerido"],
            f_email: [
                (v) => !!v || "Correo valido",
                (v) => /.+@.+/.test(v) || "Correo no valido",
            ],
            imageUrl: null,
            dialog: false,
            headers: [
                {
                    text: "Código",
                    align: "start",
                    sortable: true,
                    value: "marca",
                    dataType: "text",
                },
                {
                    text: "Marca",
                    align: "start",
                    sortable: true,
                    value: "brand",
                    dataType: "text",
                },
                {
                    text: "Inv. Inicial",
                    align: "end",
                    sortable: true,
                    value: "inv_i",
                    dataType: "text",
                },
                {
                    text: "Compras",
                    align: "end",
                    sortable: true,
                    value: "qty_ingresos",
                    dataType: "text",
                },
                {
                    text: "Ventas",
                    align: "end",
                    sortable: true,
                    value: "qty_sales",
                    dataType: "number",
                },
                {
                    text: "Inv. Final",
                    align: "end",
                    sortable: true,
                    value: "inv_f",
                    dataType: "number",
                },
                {
                    text: "IR",
                    align: "end",
                    sortable: true,
                    value: "turnover",
                    dataType: "number",
                },
                {
                    text: "ROI",
                    align: "end",
                    sortable: true,
                    value: "roi",
                    dataType: "number",
                },
                { text: "", value: "actions", align: "end", sortable: false },
            ],
            headers_brand: [
                {
                    text: "Marca",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    value: "brand",
                    dataType: "text",
                },
                {
                    text: "Refe",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    value: "refe",
                    dataType: "text",
                },
                {
                    text: "Color",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    value: "colorName",
                    dataType: "text",
                },
                {
                    text: "Inv. Inicial",
                    align: "end",
                    sortable: true,
                    filterable: true,
                    value: "inv_i",
                    dataType: "text",
                },
                {
                    text: "Compras",
                    align: "end",
                    sortable: true,
                    filterable: true,
                    value: "qty_ingresos",
                    dataType: "text",
                },
                {
                    text: "Ventas",
                    align: "end",
                    sortable: true,
                    filterable: true,
                    value: "qty_sales",
                    dataType: "number",
                },
                {
                    text: "Inv. Final",
                    align: "end",
                    sortable: true,
                    filterable: true,
                    value: "inv_f",
                    dataType: "number",
                },
                {
                    text: "IR",
                    align: "end",
                    sortable: true,
                    filterable: true,
                    value: "turnover",
                    dataType: "number",
                },
            ],
            items: [],
            items_brand: [],
            dateFrom: getdays_ago(-60),
            dateTo: getToday(),
            search: '',
        };
    },
    mounted() {
        this.get_report("marca");
    },
    methods: {
        sumTable(table, key) {
            return parseFloat(this[table].reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)).toLocaleString(2)
        },
        showBrand(e) {
            this.get_report_brand(e.marca);
            this.dialog = true;
        },
        get_report_brand(marca) {
            this.loading_status = true;
            var qry = {
                store: window.store.store_id,
                issueDate: this.dateFrom,
                filters: {
                    id_pos: window.store.store_id,
                    marca: marca,
                },
                groupby: 'refe',
            };
            webserver("get_stock_rotation", qry, (data) => {
                this.loading_status = false;
                this.items_brand = data;
            });
        },
        get_report(groupby) {
            this.loading_status = true;
            var qry = {
                store: window.store.store_id,
                issueDate: this.dateFrom,
                filters: {
                    id_pos: window.store.store_id,
                },
                groupby: groupby,
            };
            webserver("get_stock_rotation", qry, (data) => {
                data.forEach((itm) => {
                    itm.quantity = parseFloat(itm.quantity);
                    itm.tcost = parseFloat(itm.quantity) * parseFloat(itm.costo);
                    itm.tprice = parseFloat(itm.quantity) * parseFloat(itm.price);
                });
                this.loading_status = false;
                this.items = data;
            });
        },
    },
};
</script>

<style>
</style>
